<template>
  <b-card>
    <template #header>
      <b-row>
        <b-col
          :cols="12"
          :lg="11"
          class=""
        >
          <b-row class="w-100" :cols="12" :cols-lg="3">
            <b-col :cols="12" :md="6" :lg="3">
              <h4 class="mb-4 mb-lg-0">
                {{ $t('eMedia_title') }}
              </h4>
            </b-col>
            <b-col :cols="12" :md="6" :lg="3">
              <b-form-group class="mb-4 mb-lg-0">
                <b-form-select v-model="sortBy" :options="sortOptions" size="sm" />
              </b-form-group>
            </b-col>
            <b-col :cols="12" :md="6" :lg="3">
              <b-tabs
                v-model="filterByTabIndex"
                pills
                small
                :align="isMobile || isTablet ? 'left' : 'center'"
                active-nav-item-class="bg-transparent border border-info text-info"
                class="mb-4 mb-lg-0"
              >
                <b-tab v-for="(tab, i) in filterByTabs" :key="i" :title="tab.value" />
              </b-tabs>
            </b-col>
            <b-col :cols="12" :md="6" :lg="3">
              <b-tabs
                v-model="filterByPrivacyTabIndex"
                pills
                small
                :align="isMobile || isTablet ? 'left' : 'center'"
                active-nav-item-class="bg-transparent border border-danger text-danger"
                class="mb-3 mb-lg-0"
              >
                <b-tab v-for="(tab, i) in filterByPrivacyTabs" :key="i" :title="tab.value" />
              </b-tabs>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          :cols="12"
          :lg="1"
          :class="isMobile ? 'text-center' : 'text-right'"
        >
          <b-dropdown
            right
            no-caret
            toggle-class="text-decoration-none"
            variant="link"
            size="lg"
          >
            <template #button-content>
              <font-awesome-icon icon="ellipsis-vertical" />
            </template>
            <b-dropdown-item-btn
              variant="primary"
              size="sm"
              @click="onFileSelect"
            >
              {{ $t('eMedia_addMedia') }}
            </b-dropdown-item-btn>
            <b-dropdown-item-btn
              v-if="canValidate"
              variant="warning"
              outline
              size="sm"
              @click="onValidateAll"
            >
              {{ $t('eMedia_validateAll') }}
            </b-dropdown-item-btn>
          </b-dropdown>
          <input
            ref="userPhoto"
            type="file"
            name="userPhoto"
            accept="image/jpeg,image/bmp,image/png,video/mp4,video/quicktime"
            class="d-none"
            @change="onUpload"
          >
        </b-col>
      </b-row>
    </template>
    <b-row>
      <b-col
        v-for="(item, i) in mediaItems"
        :key="i"
        :cols="12"
        :md="6"
        :lg="3"
        class="mb-5 text-center"
      >
        <gallery-media-item
          ref="mediaItems"
          :key="`${itemsKey}${i}`"
          :item="item"
          :form-data="formData"
          :can-validate="canValidate"
          @on-updated="onMediaUpdated"
          @click="lightBoxIndex = i"
        />
      </b-col>
    </b-row>
    <light-box
      :items="lightBoxItems"
      :index="lightBoxIndex"
      :use-zoom-bar="true"
      :full-screen="true"
      @close="lightBoxIndex = null"
    />
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as moment from 'moment'
import GalleryMediaItem from './GalleryMediaItem'

export default {
  name: 'GalleryMedia',
  components: {
    GalleryMediaItem
  },
  props: {
    media: {
      type: Array,
      default: () => ([])
    },
    profileId: {
      type: Number,
      default: -1
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    },
    canValidate: {
      type: Boolean
    }
  },
  data () {
    return {
      userPhoto: null,
      sortBy: 'created_at',
      sortOptions: [
        { value: 'created_at', text: 'Sort by date' },
        { value: 'privacy', text: 'Sort by privacy' },
        { value: 'type', text: 'Sort by type' }
      ],
      filterByTabIndex: 0,
      filterByTabs: [
        {
          key: 0,
          value: 'All'
        },
        {
          key: 1,
          value: 'Image'
        },
        {
          key: 2,
          value: 'Video'
        }
        // , {
        //   key: 3,
        //   value: 'Profile'
        // }
      ],
      filterByPrivacyTabIndex: 0,
      filterByPrivacyTabs: [
        {
          key: 0,
          value: 'All'
        },
        {
          key: 1,
          value: 'Public'
        },
        {
          key: 2,
          value: 'Level 1'
        },
        {
          key: 3,
          value: 'Level 2'
        }
      ],
      itemsKey: null,
      lightBoxIndex: null
    }
  },
  computed: {
    ...mapGetters({
      deviceInfo: 'device'
    }),
    isMobile () {
      return this.deviceInfo?.type === 'mobile' || false
    },
    isTablet () {
      return this.deviceInfo?.type === 'tablet' || false
    },
    mediaItems () {
      const media = JSON.parse(JSON.stringify(this.media))
        .filter((i) => {
          if (this.filterByTabIndex === 1) {
            return i.type === 'image' && !i.is_profile
          } else if (this.filterByTabIndex === 2) {
            return i.type === 'video' && !i.is_profile
          } else if (this.filterByTabIndex === 3) {
            return i.is_profile
          }
          return !i.is_profile
        })
        .filter((i) => {
          if (this.filterByPrivacyTabIndex === 1) {
            return i.privacy === 0
          } else if (this.filterByPrivacyTabIndex === 2) {
            return i.privacy === 1
          } else if (this.filterByPrivacyTabIndex === 3) {
            return i.privacy === 2
          }
          return true
        })
        .sort((a, b) => {
          if (this.sortBy === 'privacy') {
            if (a.privacy > b.privacy) {
              return -1
            } else if (a.privacy < b.privacy) {
              return 1
            }
          } else if (this.sortBy === 'type') {
            if (a.type === 'image' && b.type === 'video') {
              return 1
            } else if (a.type === 'video' && b.type === 'image') {
              return -1
            }
          }
          // Sort by date by default and if sorted values is eq
          const ats = moment(a.created_at).unix()
          const bts = moment(b.created_at).unix()
          if (ats > bts) {
            return -1
          } else if (ats < bts) {
            return 1
          }
          return 0
        })
      return media || []
    },
    lightBoxItems () {
      const user = this.user ? `- <b>${this.$t('eMedia_userId')}</b>: <span>${this.user.id}</span> - <b>${this.user.display_name}</b>` : ''
      return this.mediaItems.map(i => ({
        title: `<span>${this.$dateFormat(i.created_at)}</span> - <b>${this.$t('eMedia_id')}:</b> <span>${i.id}</span> ${user}`,
        description: `<a target="_blank" href="${i.file_path}">${i.file_path}</a>`,
        src: i.file_path
      }))
    }
  },
  watch: {
    mediaItems: {
      handler () {
        this.$nextTick(() => {
          this.itemsKey = JSON.stringify(this.mediaItems.map(i => [i.id, i.privacy]))
        })
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      add: 'media/add',
      validateAll: 'media/validateAll'
    }),
    onMediaUpdated (id) {
      this.$emit('on-updated', id)
    },
    onFileSelect () {
      this.$refs.userPhoto.click()
    },
    async onUpload (e) {
      await this.add({
        id: this.profileId,
        file: e.target.files && e.target.files[0]
      })
      this.$emit('on-updated')
      this.$refs.userPhoto.value = null
    },
    onValidateAll () {
      this.validateAll({
        id: this.profileId
      })
    }
  }
}
</script>

<style scoped>

</style>
