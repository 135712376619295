<template>
  <div>
    <div
      class="position-relative d-flex flex-column align-items-center"
      @click="onImgOpen"
    >
      <div
        v-b-tooltip.hover
        class="position-absolute px-3 pt-3"
        :class="{
          'cursor-pointer': !picNotFound,
          'text-danger': item.validated === -1,
          'text-warning': item.validated === 0,
          'text-success': item.validated === 1
        }"
        style="width: 320px; text-align: right; opacity: .7;"
      >
        <font-awesome-icon
          :id="`media-${item.id}-validate-flag`"
          size="2x"
          :icon="item.validated === 1 ? 'check' : 'triangle-exclamation'"
        />
        <b-tooltip
          v-if="item.validated !== 1"
          :target="`media-${item.id}-validate-flag`"
          :variant="item.validated === -1 ? 'danger' : 'warning'"
        >
          <span v-if="item.validated === -1">
            {{ $t('eMedia_notValidatedTitle') }}
          </span>
          <span v-if="item.validated === 0">
            {{ $t('eMedia_notRecognizedTitle') }}
          </span>
        </b-tooltip>
      </div>
      <div
        v-if="retrying"
        class="position-absolute d-flex flex-column"
        style="top: 130px; left: 150px; z-index: 1"
      >
        <font-awesome-icon
          icon="spinner"
          :spin="true"
          size="5x"
        />
        {{ $t('eMedia_attempt') }}: {{ loadAttempts + 1 }}
      </div>
      <img
        v-if="item.type === 'image'"
        :id="`media-thumb-${item.id}`"
        :src="picUrl || item.file_path"
        class="img-thumbnail mb-2"
        :class="{
          'cursor-pointer': !picNotFound,
          'border-danger bg-danger': canValidate && item.validated === -1,
          'border-warning bg-warning': canValidate && item.validated === 0
        }"
        style="width: 320px; height: 320px; object-fit: cover;"
        :style="retrying ? 'opacity: 0.6;' : ''"
        crossorigin="anonymous"
        @error="onImageLoadFails"
      >
      <!--
      <video
        v-if="item.type === 'video' && !item.converted_at"
        width="320"
        height="240"
        controls
      >
        <source :src="item.file_path">
        {{ $t('eMedia_browserNotSupport') }}
      </video>
      -->
      <video
        v-if="item.type === 'video' && item.converted_at"
        width="320"
        height="240"
        :poster="item.converted_video_poster_file_path || ''"
        controls
      >
        <source
          v-for="(v, i) in item.converted_video_file_paths"
          :key="i"
          :src="v"
        >
        {{ $t('eMedia_browserNotSupport') }}
      </video>
      <div v-if="item.type === 'video' && !item.converted_at" class="mb-2">
        {{ $t('eMedia_videoConv') }}
      </div>
      <div v-if="item.type === 'video' && !!progress && !progress.done">
        <div v-for="(v, i) of progress" :key="i" class="d-flex flex-row justify-content-between align-items-center">
          <div v-if="i !== 'done' && i !== 'batch'" class="text-left" style="width: 60px">
            {{ i }}
          </div>
          <b-progress
            v-if="!v.error && i !== 'done' && i !== 'batch'"
            :value="v.progress"
            max="100"
            show-progress
            :animated="v.progress !== 100"
            style="width: 60px"
          />
          <span v-else-if="v.error" v-shtml="v.error" />
        </div>
      </div>
    </div>
    <media-transform
      v-if="item.type === 'image' && !picNotFound"
      :image="item"
      @on-updated="onMediaUpdated"
    />
    <div class="mt-2 px-2">
      <p class="mb-1">
        <b>{{ $t('eMedia_id') }}:</b>
        <span
          :id="`media-info-id-${item.id}`"
        >{{ item.id }}</span> &minus;
        <span
          :id="`media-info-date-${item.id}`"
        >{{ $dateFormat(item.created_at) }}</span>
      </p>

      <div
        v-if="!picNotFound"
        class="d-inline-flex flex-wrap align-items-center justify-content-around mb-3"
      >
        <div class="d-inline-flex align-items-center w-100 my-3">
          <span class="mr-2">{{ $t('eMedia_privte') }}</span>
          <b-form-select
            v-model="privacy"
            :options="privacyOptions"
            size="sm"
          />
        </div>

        <!--
        <b-form-checkbox
          v-model="block_only_vip_status"
          size="sm"
          switch
          class="mr-2"
        >
          {{ $t('eMedia_blocked') }}
        </b-form-checkbox>
        -->

        <b-form-checkbox
          v-model="no_profile_image"
          size="sm"
          switch
          class="mr-2"
        >
          {{ $t('eMedia_noProfileImage') }}
        </b-form-checkbox>
      </div>
      <div
        v-else
        class="d-flex flex-wrap align-items-center justify-content-around mb-3 text-danger font-weight-bold"
      >
        {{ $t('eMedia_notFound') }}
      </div>

      <b-btn
        v-if="canValidate && item.validated !== 1"
        variant="warning"
        size="sm"
        @click="onMediaValidate"
      >
        {{ $t('eMedia_validate') }}
      </b-btn>

      <b-btn
        v-if="!picNotFound && item.type === 'image'"
        variant="outline-secondary"
        size="sm"
        @click="onMediaProfileImage"
      >
        {{ $t('eMedia_setProfileImage') }}
      </b-btn>

      <b-btn
        v-if="loadingFails"
        variant="outline-secondary"
        size="sm"
        @click="() => { loadAttempts = 0; onImageLoadFails(); }"
      >
        {{ $t('eMedia_reloadImage') }}
      </b-btn>

      <b-btn
        variant="outline-danger"
        size="sm"
        @click="onMediaDelete"
      >
        {{ $t('eMedia_delete') }}
      </b-btn>
    </div>
    <modal-avatar
      v-model="modalAvatarState"
      :src="imgPath"
      :media-id="item.id"
      @on-store="onMediaUpload"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

const noImg = require('@/static/infowing.png')
const LOAD_ATTEMPTS = 5

export default {
  name: 'GalleryMediaItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    canValidate: {
      type: Boolean
    }
  },
  data () {
    return {
      loadAttempts: 0,
      picUrl: null,
      picNotFound: false,
      modalAvatarState: false,
      privacy: this.item.privacy,
      block_only_vip_status: !!this.item.block_only_vip_status,
      no_profile_image: !!this.item.no_profile_image,
      progress: this.item?.progress
        ? this.transProgress(this.item.progress)
        : null
    }
  },
  computed: {
    ...mapGetters({
      getProgressKey: 'media/getProgressKey',
      getProgress: 'media/getProgress'
    }),
    privacyOptions () {
      return this.formData?.mediaPrivacy || []
    },
    imgPath () {
      // HACK to avoid cors error
      return this.item.file_path.replace(/^h/, 'H')
    },
    retrying () {
      return this.picNotFound && this.loadAttempts < LOAD_ATTEMPTS
    },
    loadingFails () {
      return this.picNotFound && this.loadAttempts === LOAD_ATTEMPTS
    },
    isProgressDone () {
      return this.progress?.done || false
    }
  },
  watch: {
    async privacy (n) {
      if (n >= 0) {
        await this.update({
          id: this.item.id,
          data: { privateVal: n }
        })
      }
    },
    async block_only_vip_status (n) {
      if (typeof n === 'boolean') {
        await this.update({
          id: this.item.id,
          data: { blockedVal: n }
        })
      }
    },
    async no_profile_image (n) {
      if (typeof n === 'boolean') {
        await this.update({
          id: this.item.id,
          data: { noProfileVal: n }
        })
      }
    },
    getProgressKey () {
      this.progress = this.getProgress(this.item.id)
    },
    isProgressDone (n) {
      if (n) {
        this.$media.stopWatch(this.item.id)
        this.$emit('on-updated', this.item.id)
      }
    }
  },
  mounted () {
    this.privacy = this.item.privacy
    this.block_only_vip_status = !!this.item.block_only_vip_status
    this.no_profile_image = !!this.item.no_profile_image
    if (this.item.type === 'video' && !this.item.converted_at) {
      this.$media.watch(this.item.id)
    }
  },
  methods: {
    ...mapActions({
      update: 'media/update',
      delete: 'media/delete',
      validate: 'media/validate',
      addAvatar: 'media/addAvatar'
    }),
    onMediaUpdated (id) {
      this.$emit('on-updated', id)
    },
    async onMediaUpload ({ blob, mediaId }) {
      await this.addAvatar({
        mediaId,
        file: blob
      })
      this.$emit('on-updated')
    },
    onMediaDelete () {
      this.$confirmDanger({
        title: this.$t('eDoc_list_deleteConfirmTitle'),
        body: this.$t('eDoc_list_deleteConfirmBody', { id: this.item.id }),
        okTitle: this.$t('eDoc_list_deleteConfirmOk'),
        cancelTitle: this.$t('eDoc_list_deleteConfirmCancel')
      })
        .then(async (value) => {
          if (value) {
            try {
              await this.delete({ id: this.item.id })
              this.$emit('on-updated')
            } catch (e) {
              //
            } finally {
              //
            }
          }
        })
    },
    onMediaProfileImage () {
      this.modalAvatarState = true
    },
    onImgOpen () {
      if (this.item.type === 'image' && !this.picNotFound) {
        this.$emit('click', this.item)
      }
    },
    async onMediaValidate () {
      const onError = () => {
        this.$bvModal.msgBoxOk(this.$t('eMedia_errValidateBody'), {
          title: this.$t('eMedia_errValidateTitle'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Ok',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
      }
      const onSuccess = () => {
        this.$emit('on-updated')
      }
      await this.validate({ id: this.item.id, onSuccess, onError })
    },
    onImageLoadFails () {
      this.picUrl = noImg
      this.picNotFound = true
      if (this.loadAttempts < LOAD_ATTEMPTS) {
        setTimeout(() => {
          this.$store.dispatch('setLoadingEnable', false)
          this.$axios.$get(this.item.file_path)
            .catch(() => {
              this.loadAttempts++
              this.onImageLoadFails()
            })
            .then((res) => {
              if (typeof res !== 'undefined') {
                this.picUrl = this.item.file_path + '?h=' + Math.random().toString(36).substr(2, 5)
                this.picNotFound = false
                this.loadAttempts = 0
              }
            })
            .finally(() => {
              this.$store.dispatch('setLoadingEnable', true)
            })
        }, 2500)
      }
      // else {
      //   this.picUrl = noImg
      //   this.picNotFound = true
      // }
    },
    transProgress (progress) {
      const r = progress
      delete r?.id
      delete r?.name
      return r
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
